var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:(_vm.intraction)},[(_vm.PlayerApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.PlayerApi}}):_vm._e(),(_vm.EventApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.EventApi}}):_vm._e(),(_vm.InventoryApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.InventoryApi}}):_vm._e(),(_vm.error)?_c('ErrorDialog',{attrs:{"error":_vm.error,"message":_vm.errorMessage},on:{"exitError":_vm.extiError}}):_vm._e(),_c('div',{staticClass:"tab-bar-card"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"hamochi"},[_c('div',{staticClass:"image"},[_c('v-img',{attrs:{"src":require('@/assets/explore/hamochi.png'),"width":"150"}})],1),_c('div',{staticClass:"chat"},[_c('div',{staticClass:"speech-bubble"},[_vm._v(" "+_vm._s(_vm.$t("string.giftDescription"))+" ")])])]),_c('div',{staticClass:"gift pa-1"},[_c('v-img',{staticClass:"pb-3 px-2",attrs:{"src":require('@/assets/explore/gift_bg.png'),"width":"150"}},[(_vm.totalStars > 0 && _vm.maxStars == _vm.totalStars)?_c('v-img',{staticClass:"gift-shine",attrs:{"src":require('@/assets/explore/glow.png'),"width":"120"}}):_vm._e(),_c('v-img',{class:_vm.totalStars > 0 && _vm.maxStars == _vm.totalStars
                ? 'gift-shake'
                : 'gift-lock',attrs:{"src":require('@/assets/explore/gift.png'),"width":"150"},on:{"click":function($event){return _vm.gift()}}})],1)],1)]),_c('div',{staticClass:"stars-panel"},[_c('div',{staticClass:"star-progress-back"}),_c('div',{class:_vm.progress == '84%' ? 'star-progress-full' : 'star-progress',style:({ width: _vm.progress })}),_c('v-icon',{staticClass:"pe-3",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),_vm._v(" "+_vm._s(_vm.totalStars)+"/"+_vm._s(_vm.maxStars)+" ")],1)]),_c('div',{staticClass:"d-flex justify-space-between py-2 align-center"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"disabled":!_vm.isOngoing,"id":"StartTutorial","small":"","color":"yellow"},on:{"click":() => {
            _vm.$intro().exit();
            _vm.$router.push({
              name: 'PageEventGameTutorial',
            });
          }}},[_vm._v(" "+_vm._s(_vm.$t("string.tutorial"))+" "),(_vm.isGameTutCompleted && _vm.isOngoing)?_c('v-img',{staticClass:"ms-2",attrs:{"src":require('@/assets/explore/menu/tick.png'),"width":"20"}}):_vm._e(),(!_vm.isOngoing)?_c('v-icon',[_vm._v(" mdi-lock ")]):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t("string.totalScore"))+" : "+_vm._s(_vm.totalScore)+" ")]),_c('div',{staticClass:"d-flex align-center ticket-card"},[_c('v-img',{attrs:{"contain":"","width":"30","src":require('@/assets/explore/menu/ticket.png')}}),(_vm._event_data.Save)?_c('div',{staticClass:"text-h5 ps-2 game-title"},[_vm._v(" "+_vm._s(JSON.parse(_vm._event_data.Save.data).token)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"pa-1 d-flex flex-wrap justify-space-between",staticStyle:{"position":"relative"}},_vm._l((_vm.levels),function(level,i){return _c('div',{key:i,staticClass:"level-cart",attrs:{"id":i == 0 ? 'StartLevel' : ''},on:{"click":() => {
          _vm.$intro().exit();
          if (_vm.isOngoing) {
            _vm.$router.push({
              name: 'PageEventLevelDetail',
              params: { id: level.Level.id },
            });
          }
        }}},[_c('div',{staticClass:"level-img"},[_c('div',{staticClass:"container-1x1"},[_c('div',{staticClass:"aspect-ratio-item"},[(_vm.bossList[i])?_c('v-img',{class:_vm.bossList[i].inventory > 0 ? '' : 'no-boss',attrs:{"src":_vm.bossList[i].images.icon.url,"width":"150"}}):_vm._e()],1)])]),_c('div',{staticClass:"d-flex justify-center text-h5 mt-2",staticStyle:{"background-color":"#cbb18f"}},[_vm._v(" "+_vm._s(level.EventRecord ? level.EventRecord.topScore : "000")+" ")]),_c('div',{staticClass:"d-flex justify-space-around my-2"},[_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 0
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")]),_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 1
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")]),_c('v-icon',{attrs:{"color":level.EventRecord && level.EventRecord.topStar > 2
              ? 'yellow darken-2'
              : 'gray'}},[_vm._v(" mdi-star")])],1),_c('div',{staticClass:"level-go"},[_c('v-btn',{staticClass:"text-h6",attrs:{"disabled":!_vm.isOngoing,"color":"yellow","width":"100%"},on:{"click":() => {
              _vm.$intro().exit();
              _vm.$router.push({
                name: 'PageEventLevelDetail',
                params: { id: level.Level.id },
              });
            }}},[(_vm.isOngoing)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("action.go"))+" ")]):_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1)])}),0),_c('v-dialog',{attrs:{"width":"460","content-class":"mail-box","overlay-opacity":"0.8","persistent":""},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}},[(_vm.mailOpen)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mt-3 mail-text text-h4"},[_vm._v(_vm._s(_vm.$t("string.you_got")))]),_c('div',{staticClass:"mail-text text-h3 d-flex align-center"},[_c('v-img',{attrs:{"contain":"","width":"80","src":require('@/assets/explore/menu/ticket.png')}}),_vm._v(" X 20 ")],1),_c('v-img',{staticClass:"mx-auto mail-img",attrs:{"src":require('@/assets/explore/mail_open.png'),"width":"70%","contain":""}}),_c('v-btn',{staticClass:"text-h5 mb-3",attrs:{"height":"60","width":"200","color":"yellow"},on:{"click":() => {
            _vm.startTut();
            _vm.mail = false;
          }}},[_vm._v(" OK ")])],1):_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"mt-3 mail-text text-h3"},[_vm._v("Tata!")]),_c('div',{staticClass:"mail-text text-h4"},[_vm._v(_vm._s(_vm.$t("string.you_have_mail")))]),_c('v-img',{staticClass:"mx-auto mail-img",attrs:{"src":require('@/assets/explore/mail_close.png'),"width":"70%","contain":""}}),_c('v-btn',{staticClass:"text-h5 mb-3",attrs:{"height":"60","width":"200","color":"yellow"},on:{"click":() => {
            _vm.mailOpen = true;
          }}},[_vm._v(" "+_vm._s(_vm.$t("string.open"))+" ")])],1)]),(_vm.tutorialChat && _vm.chatStart)?_c('AbstractChat',{attrs:{"X1":"Hamochie","chats":_vm.tutorialChat,"callback":_vm.callback}}):_vm._e(),_c('SkipTut')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }